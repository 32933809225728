
export default {
    name: "FormWide",
    props: {
        usdtPrice: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            tether: {
                amount: 0,
                flags: {
                    focus: false,
                }
            },
            rial: {
                amount: 0,
                flags: {
                    focus: false,
                }
            }
        }
    },
    watch: {
        'tether.amount'(newVal) {
            if (!this.rial.flags.focus) {
                this.rial.amount = (newVal * this.usdtPrice);
            }
        },
        'rial.amount'(newVal) {
            if (!this.tether.flags.focus) {
                this.tether.amount = parseFloat(parseFloat(newVal / this.usdtPrice).toFixed(2));
            }
        },
    },
    methods: {
        tetherFocus(flag) {
            this.tether.flags.focus = flag;
        },
        rialFocus(flag) {
            this.rial.flags.focus = flag;
        },
        async buy() {
            if (this.$auth.loggedIn) {
                if (this.tether.amount >= 10) {
                    await this.$store.dispatch("buySell/setOrder", { type: 'buy', amount: this.tether.amount });
                    await this.$router.push('/pre-factor/buy');
                } else {
                    this.$store.dispatch("messagePopup/error", "حداقل مقدار برای خرید تتر برابر 10 عدد می باشد.");
                }
            } else {
                this.$store.dispatch("messagePopup/error", "ابتدا به حساب کاربری خود وارد شوید.");
            }
        },
        async sell() {
            if (this.$auth.loggedIn) {
                if (this.tether.amount >= 10) {
                    await this.$store.dispatch("buySell/setOrder", { type: 'sell', amount: this.tether.amount });
                    await this.$router.push('/pre-factor/sell');
                } else {
                    this.$store.dispatch("messagePopup/error", "حداقل مقدار برای فروش تتر برابر 10 عدد می باشد.");
                }
            } else {
                this.$store.dispatch("messagePopup/error", "ابتدا به حساب کاربری خود وارد شوید.");
            }
        }
    },
    beforeMount() {
        if (this.$store.state.buySell.type && this.$store.state.buySell.amount) {
            this.tether.amount = this.$store.state.buySell.amount;
        }
        if (localStorage.getItem('buy-sell')) {
            localStorage.removeItem('buy-sell');
        }
    }
}
