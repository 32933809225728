import { render, staticRenderFns } from "./Form.vue?vue&type=template&id=14ce37c6&scoped=true"
import script from "./Form.vue?vue&type=script&lang=js"
export * from "./Form.vue?vue&type=script&lang=js"
import style0 from "./Form.vue?vue&type=style&index=0&id=14ce37c6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14ce37c6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreInputNumberField: require('/app/components/core/input/NumberField.vue').default,PublicLirIcon: require('/app/components/public/lir/Icon.vue').default,CoreButton: require('/app/components/core/Button.vue').default})
