
export default {
  name: "PublicSliderBlogPosts",
  props: {
    items: {
      type: Array,
    },
  },

  methods: {
    getImagePath(image) {
      const base64ToArray = image?.split(";base64,");
      if (base64ToArray)
        if (base64ToArray.length > 1) return image;
        else return this.$config.cdnUrl + "/" + image;
    },
  },
};
