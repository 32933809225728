
export default {
  name: "PublicSliderComments",
  data() {
    return {
      testData: [
        {
          image: "/layouts/temp/user.png",
          name: "رضا کمالوند",
          date: "1402/01/14",
          body: "از ابتدای فعالیت شون  باهاشون کار میکنم واقعا بی نظیره خیلی سریع تسویه ها انجام میشه مهم تر اینکه همیشه بهترین نرخ رو ارائه میکنن.",
        },
        {
          image: "/layouts/temp/user.png",
          name: "احمد رحمانی",
          date: "1402/01/18",
          body: "تتر اکسچنج واقعا بهترینه و کارش حرف نداره",
        },
        {
          image: "/layouts/temp/user.png",
          name: "نسرین آزاده",
          date: "1402/01/22",
          body: "نرخ خوب کارمزد کم سرعت تبادل بالا احراز هویت آنی شما حرف ندارید",
        },
        {
          image: "/layouts/temp/user.png",
          name: "محمد رمضانی",
          date: "1402/01/15",
          body: "از تیم عالی تتر اکسچنج بابت پشتیبانی خوبی که دارن واقعا سپاس گذارم مشکل پیش آمده در خصوص حوزه فعالیتی نبود و در ارتباط با سایر ارزها بود ولیکن به خوبی بنده رو راهنمایی کردن. خدا قوت به تیم خوب تتر اکسچنج",
        },
        {
          image: "/layouts/temp/user.png",
          name: "علی اصغر حسین زاده اصل",
          date: "1402/01/03",
          body: "فقط خواستم این تجربه خوب با سایر افراد به اشتراک بزارم، یا علی",
        },
        {
          image: "/layouts/temp/user.png",
          name: "سمانه رضایی ثانی",
          date: "1402/01/08",
          body: "مرسی امیدوارم همیشه همینقدر خوب بمونید سال نو مبارک ",
        },
        {
          image: "/layouts/temp/user.png",
          name: "شیما خداد",
          date: "1402/02/01",
          body: "برای نقل انتقال ارز تتر به خارج از کشور بنده رو راهنمایی کردن و حدود6 ماه هست که تتر ازهمین صرافی خریداری می کنم و هر زمان از پشتیبانی کمک خواستم سریعا  و با صبر حوصله بنده رو راهنمایی کردن.بابت خدماتی خوبی که دارید سپاس گذارم",
        },
        {
          image: "/layouts/temp/user.png",
          name: "نیلوفر اصغر زاده",
          date: "1401/12/28",
          body: "عالی عالی عالی ممنون از مدیریت محترم کاش میشد سایر رمز ارزهارو هم اضافه کنید.",
        },
        {
          image: "/layouts/temp/user.png",
          name: "بابک اصلانی نصب",
          date: "1401/12/21",
          body: "با سلام از دوستان که در صرافی فعالیت دارن  پلتفرم تتر اکسچنج به بنده معرفی کرد خواستم اینجا پیام بزارم ازحامد جان معرف بنده به این صرافی  و تیم تتر اکسچنج کمال تشکر داشته باشم بابت این سرعت بالا و قیمت خوبی که دارید. تا همیشه موفق باشید",
        },
      ],
    };
  },
};
