
export default {
  name: "CaptureVideo",

  data: () => ({
    front: "environment",
    count: 0,
    video: null,
    mediaRecorder: null,
    parts: [],
    stream: null,
    status: false,
    timerInterval: null,
  }),

  mounted() {
    this.video = this.$refs.video;
    this.startCapture();
  },

  beforeDestroy() {
    if (this.stream) this.stopCapture();
  },

  methods: {
    async startCapture() {
      const constraints = {
        video: {
          facingMode: this.front,
        },
        audio: true,
        width: { min: 160, ideal: 270, max: 640 },
        height: { min: 240, ideal: 480, max: 480 },
      };
      this.count = 0;
      document.getElementById("stopRecord").style.display = "none";
      document.getElementById("timer").style.display = "none";
      if (navigator.mediaDevices) {
        try {
          this.stream = await navigator.mediaDevices.getUserMedia(constraints);
        } catch (err) {
          console.log(err);
        }
        this.video.srcObject = this.stream;
      } else {
        alert("دوربین شناسایی نشد.");
        this.isValid = false;
      }
    },

    async startRecord() {
      await this.startCapture();
      let self = this;
      document.getElementById("startRecord").style.display = "none";
      document.getElementById("timer").style.display = "block";
      document.getElementById("stopRecord").style.display = "block";
      this.timerInterval = setInterval(function () {
        self.count++;
        document.getElementById("timer").innerHTML = self.count + " ثانیه";
      }, 1000);
      this.mediaRecorder = new MediaRecorder(this.stream);
      this.mediaRecorder.start(1000);
      this.mediaRecorder.ondataavailable = function (e) {
        self.parts.push(e.data);
      };
    },

    reverse() {
      this.front = this.front === "user" ? "environment" : "user";
      this.video.srcObject.getVideoTracks().forEach((track) => {
        track.stop();
      });
      this.startCapture();
    },

    stopCapture() {
      if (this.mediaRecorder != null)
        if (this.mediaRecorder.state === "recording") {
          this.mediaRecorder.stop();
        }
      this.parts = [];
      clearInterval(this.timerInterval);
      this.count = 0;
      this.stream.getTracks()[0].stop();
      this.stream.getTracks()[1].stop();
    },

    stopRecord() {
      this.count = 0;
      document.getElementById("startRecord").style.display = "block";
      document.getElementById("timer").style.display = "none";
      document.getElementById("stopRecord").style.display = "none";
      const blob = new Blob(this.parts, {
        type: "video/mp4",
      });
      let videoURL = window.URL.createObjectURL(blob);
      this.stopCapture();
      this.$emit("Recorded", {
        url: videoURL,
        blob,
      });
    },
  },
};
