
export default {
  name: "Form",
  props: {
    lirPrice: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      lir: {
        amount: 0,
        flags: {
          focus: false,
        }
      },
      rial: {
        amount: 0,
        flags: {
          focus: false,
        }
      }
    }
  },
  watch: {
    'lir.amount'(newVal) {
      if (!this.rial.flags.focus) {
        this.rial.amount = parseFloat(parseFloat(newVal * this.lirPrice).toFixed(2));
      }
    },
    'rial.amount'(newVal) {
      if (!this.lir.flags.focus) {
        this.lir.amount = parseFloat(parseFloat(newVal / this.lirPrice).toFixed(2));
      }
    },
  },
  methods: {
    lirFocus(flag) {
      this.lir.flags.focus = flag;
    },
    rialFocus(flag) {
      this.rial.flags.focus = flag;
    },
    async buy() {
      if (this.$auth.loggedIn) {
        if (this.rial.amount >= 10000) {
          await this.$store.dispatch("buySellLir/setOrder", { type: 'buy', amount: this.lir.amount });
          await this.$router.push('/pre-factor/lir');
        } else {
          this.$store.dispatch("messagePopup/error", "حداقل مقدار برای خرید لیر برابر 10,000 تومان می باشد.");
        }
      } else {
        this.$store.dispatch("messagePopup/error", "ابتدا به حساب کاربری خود وارد شوید.");
      }
    },
  },
  beforeMount() {
    if (this.$store.state.buySellLir.type && this.$store.state.buySellLir.amount) {
      this.lir.amount = this.$store.state.buySellLir.amount;
    }
    if (localStorage.getItem('buy-sell')) {
      localStorage.removeItem('buy-sell');
    }
  }
}
