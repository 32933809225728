
export default {
  name: "PublicCreditCardTether",
  props: {
    type: {
      type: String,
      default: 'card'
    }
  }
}
