
export default {
  name: "TetherIcon",
  props: {
    filled: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'light',
    }
  },
  computed: {
    background() {
      if (this.filled) {
        if (this.theme === 'light') {
          return 'var(--primary)';
        } else if (this.theme === 'dark') {
          return 'var(--white)';
        }
      } else {
        return false;
      }
    },
    borderColor() {
      if (this.theme === 'light') {
        if (this.filled) {
          return 'var(--primary)';
        } else {
          return 'var(--primary)';
        }
      } else {
        if (this.filled) {
          return 'var(--white)';
        } else {
          return 'var(--primary)';
        }
      }
    },
    mainColor() {
      if (this.theme === 'light') {
        if (this.filled) {
          return 'var(--white)';
        } else {
          return 'var(--primary)';
        }
      } else {
        if (this.filled) {
          return 'var(--primary)';
        } else {
          return 'var(--white)';
        }
      }
    }
  }
}
