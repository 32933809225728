import { render, staticRenderFns } from "./FormWide.vue?vue&type=template&id=99f05fba&scoped=true"
import script from "./FormWide.vue?vue&type=script&lang=js"
export * from "./FormWide.vue?vue&type=script&lang=js"
import style0 from "./FormWide.vue?vue&type=style&index=0&id=99f05fba&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99f05fba",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PublicTetherIcon: require('/app/components/public/tether/Icon.vue').default,CoreInputNumberField: require('/app/components/core/input/NumberField.vue').default,CoreButton: require('/app/components/core/Button.vue').default})
