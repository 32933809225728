import { render, staticRenderFns } from "./TabBtnsFull.vue?vue&type=template&id=109e7525&scoped=true"
import script from "./TabBtnsFull.vue?vue&type=script&lang=js"
export * from "./TabBtnsFull.vue?vue&type=script&lang=js"
import style0 from "./TabBtnsFull.vue?vue&type=style&index=0&id=109e7525&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "109e7525",
  null
  
)

export default component.exports