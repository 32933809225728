
// import Loader from './Loader.vue'
export default {
  name: "VideoCapture",
  props: {
    uploadUrl: {
      default: null,
    },
    recordBtnContent: {
      default: "Record",
    },
    stopBtnContent: {
      default: "◼",
    },
    cancelBtnContent: {
      default: "Cancel",
    },
    doneBtnContent: {
      default: "OK",
    },
  },
  /*components: {
    Loader
  },*/
  data() {
    return {
      errText: null,
      isValid: true,
      isUploading: false,
      isRecording: false, // recording mode identifier
      isFinished: false, // finished recording - action buttons indicator
      recorder: null, // component wide MediaRecorder
      connection: null, // component wide WebSocket
      videoUrl: null, // link to video - assigned when done writing video file
    };
  },
  created() {
    if (!this.uploadUrl) this.errText = "There is no upload url available";
    this.getWebSocket(); // initialize connection to WebSocket
  },
  mounted() {
    this.resetVideo();
  },
  methods: {
    // reset video display with media device media stream
    resetVideo() {
      this.isFinished = false;
      this.isRecording = false;
      this.isLoading = true;
      this.$refs.videoRec.muted = true;
      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: { min: 160, ideal: 270, max: 640 },
            height: { min: 240, ideal: 480, max: 480 },
          },
          audio: true,
        })
        .then(this.gotStream)
        .catch(() => (this.isValid = false));
    },
    // start recoording
    record() {
      if (!this.uploadUrl) return;
      this.recorder.start();
      this.isRecording = true;
    },
    // stop recording
    stop() {
      this.recorder.stop();
      this.isRecording = false;
      this.isFinished = true;
      this.done();
      // this.connection.send("DONE");
    },
    // reset video diaply and emit video file url
    done() {
      this.resetVideo();
      this.$emit("input", this.videoUrl);
    },
    // initialize MediaRecorder and video element source
    gotStream(mediaStream) {
      this.recorder = new MediaRecorder(mediaStream, {
        mimeType: "video/webm",
        audioBitsPerSecond: 128000,
      });
      this.recorder.ondataavailable = this.videoDataHandler;
      this.$refs.videoRec.src = null;
      this.$refs.videoRec.srcObject = mediaStream;
      this.toggleVideo();
    },
    // handle sending data for writing using the given WebSocket
    videoDataHandler(event) {
      this.isUploading = true;
      let reader = new FileReader();
      reader.readAsArrayBuffer(event.data);
      reader.onloadend = () => {
        this.connection.send(reader.result);
      };
    },
    // update video when file written
    updateVideoFile(fileName) {
      this.videoUrl = this.uploadUrl + fileName + ".webm";
      this.toggleVideo();
      this.$refs.videoRec.srcObject = null;
      this.$refs.videoRec.src = this.videoUrl;
      this.isUploading = false;
    },
    // toggle video display
    toggleVideo() {
      this.$refs.videoRec.loop = !this.$refs.videoRec.loop;
      this.$refs.videoRec.controls = !this.$refs.videoRec.controls;
    },
  },
};
