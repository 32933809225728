
export default {
  name: "PublicCreditCardRial",
  props: {
    type: {
      type: String,
      default: 'card'
    }
  },
  methods: {
    openWithdraw() {
      this.$store.dispatch('dashboardPopupForm/showPopup', 'withdraw-rial');
      /*if (this.$auth.user.isKYC) {
        this.$store.dispatch('dashboardPopupForm/showPopup', 'withdraw-rial');
      } else {
        this.$store.dispatch("messagePopup/error", "برای برداشت تومان نیاز هست که فرآیند احراز هویت خود را تکمیل کنید.");
      }*/
    }
  }
}
