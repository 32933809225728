
export default {
  name: "NewInfo",
  props: {
    boxStyle: {
      type: String,
      default: 'index',
    },
  }
}
