
export default {
    name: "TabBtns",
    props: {
        buttons: Array,
        bgGray: Boolean,
        activeIndex: [String, Number],
        sm: Boolean,
    },
    data() {
        return {
            selectedTabIndex: 0,
        }
    },
    methods: {
        selectTab(id) {
            this.selectedTabIndex = id;
            this.$emit('activeBtn', id);
        }
    }
}
